@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@100;400;500;700;800;900&display=swap");

:root {
  --main: #0a192f;
  --mainlight: #172a46;
  --white: #ccd6f6;
  --cyan: #5ff0d0;
  --transparentBlue: #172a469e;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%; 
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  background: #000;
  color: #fff;
  font-family: "Dosis", sans-serif;
  font-size: 2.75em;
  text-align: center;
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background-color: var(--transparentBlue);
  outline: 1px solid var(--transparentBlue);
}

*::-webkit-scrollbar-thumb {
  background-color: #3f3f3f;
}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

#root {
  width: 100%;
  height: 100%;
}

#page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 5px auto;
  font-family: "Kanit", sans-serif;
}

h1 {
  letter-spacing: -0.025em;
  font-size: clamp(3.2rem, 5vw, 5.2rem);
  font-weight: 800;
  line-height: 1.05;
}

h2 {
  letter-spacing: -0.025em;
  font-size: clamp(2.2rem, 4vw, 4.2rem);
  font-weight: 800;
  line-height: 1.05;
}

#site-title {
  letter-spacing: -0.025em;
  font-size: 1em;
  font-weight: 800;
  line-height: 1.05;
  text-align: left;
}

#background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

#bg-video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

#fg-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#overlay-texture {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background: #000000;
  pointer-events: none;
  opacity: 0.2;
}

#signal-animation-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
}

#page-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

#main-scene {
  position: relative;
  z-index: 1;
  width: 100%;
}

#main-info-container {
  width: 1280px;
  height: auto;
  padding: 1em;
  position: absolute;
  z-index: 100;
  bottom: 0;
  right: 0;
}

#stats-meta {
  width: 100%;
  padding: 1em;
  background: var(--transparentBlue);
  box-shadow: inset 0 0 5px #05c7f2, 0 0 10px #05c7f2;
  border: 1px solid #05aff2;
  border-radius: 2px;
}

#stats-container {
  width: 100%;
  margin: 25px 0 0 0;
}

#stats-container h3 {
  text-align: left;
  margin-bottom: 10px;
}

.stat-name {
  font-weight: 200;
  text-shadow: #05aff2 0px 0px 10px;
  letter-spacing: 2px;
}

.stat-row {
  width: 100%;
  margin: 20px 0;
}

#signal-status .stat-name {
  text-align: left;
  font-size: 32px;
  margin-bottom: 15px;
  font-weight: bold;
  letter-spacing: 4px;
}
#signal-status.stat-row {
  margin-top: 0;
}

.stat-value-container {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.stat-value-container .MuiCircularProgress-root {
  width: 250px !important;
  height: 250px !important;
}

.stat-value-container .MuiTypography-root {
  font-size: 1.75em;
  font-family: "Noto Sans Display", sans-serif;
  font-weight: 500;
  letter-spacing: 0;
  color: #fff;
  text-shadow: 0 0 0 #fff, 0 0 4px #fff, 0 0 10px #00c6e6, 0 0 10px #00e6c2,
    0 0 10px #00cde6, 0 0 10px #00b1e6, 0 0 10px #00e0e6;
  text-shadow: #05aff2 0px 0px 20px;
}

.stat-value-container .css-1rn30mb-MuiCircularProgress-root {
  color: #05c7f2;
}

.stat-value-container .css-oxts8u-MuiCircularProgress-circle {
  stroke-width: 2;
}

.stat-value {
  font-size: 3em;
  font-weight: 500;
  text-shadow: 0 0 0 #fff, 0 0 4px #fff, 0 0 10px #00c6e6, 0 0 10px #00e6c2,
    0 0 10px #00cde6, 0 0 10px #00b1e6, 0 0 10px #00e0e6;
  color: #fff;
  text-shadow: #05aff2 0px 0px 20px;
  font-family: "Noto Sans Display", sans-serif;
}

#event-log {
  text-align: left;
  margin-bottom: 0;
}
#event-log .stat-name {
  margin: 5px 0 15px 0;
  border-bottom: 1px solid #797979;
  padding-bottom: 10px;
  font-size: 36px;
}
#log-response-container {
  overflow-y: auto;
  height: 100px;
  font-size: 0.75em;
  line-height: 1.25em;
  font-family: "Noto Sans Mono", monospace;
}

.event {
  margin: 5px 0;
}
.event-log-info {
  color: #e3e384;
}
.event-log-error {
  color: #e14545;
}
.event-log-success {
  color: #4a9b4a;
}

#constellation-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 580px;
  width: 100%;
  background: #000;
  background: linear-gradient( 0deg, rgba(22, 22, 22, 1) 0%, rgba(27, 27, 27, 1) 76%, rgba(36, 36, 36, 1) 100% );
  border: 1px solid #2c2f32;
  border-radius: 2px;
  border: 1px solid var(--cyan);
  background: var(--transparentBlue);
  box-shadow: inset 0 0 5px #05c7f2, 0 0 10px #05c7f2;
  border: 1px solid #05aff2;
}

#constellation {
  width: 95%;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  position: relative;
}

.constellation-canvas {
  position: absolute;
}

.locked,
.unlocked {
  background: green;
  padding: 10px 1em;
  min-width: 100px;
  text-transform: uppercase;
  font-family: "Noto Sans Display", sans-serif;
}

.locked {
  background: green;
  box-shadow: 0 0 0.25em 0 #44a92a, 0 0 0.25em 0 #44a92a;
}

.unlocked {
  background: red;
  box-shadow: 0 0 0.25em 0 #c12d2d, 0 0 0.25em 0 #c12d2d;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.column-flow {
  flex-flow: column;
}

.fade-animation {
  animation: fade 5s steps(90) forwards;
 -webkit-animation: fade 5s steps(90) forwards;
  -moz-animation: fade 5s steps(90) forwards;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.0;
  }
}